import swiper from 'swiper';

var topHero = new swiper('.js-spotSlider', {
  slidesPerView: 'auto',
  spaceBetween: 15,
  autoHeight: true,
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: true,
  },
});