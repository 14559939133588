import isMobile from "../../plugins/is-mobile.js";
import swiper from "swiper";

var h = $(window).height();

if (isMobile(520)) {
  $(".js-mainSlider").css({
    height: h + "px",
  });
}

var topHero = new swiper(".js-mainSlider", {
  effect: "fade",
  loop: true,
  speed: 1000,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  slidesPerView: "auto",
  // autoHeight: true,
  pagination: {
    el: ".top-hero-mainImage-pagination",
  },
});
