import smoothScroll from 'smooth-scroll';

if ($('.modelcourse-hero').length > 0) {
  let options = { 
    speed: 500,
    offset: window.innerWidth >= 1024 ? 30 : 30
  };
  
  let scroll = new smoothScroll('a[href*="#"]', options);

} else {
  let options = { 
    speed: 500,
    offset: window.innerWidth >= 1024 ? 0 : 0
  };
  
  let scroll = new smoothScroll('a[href*="#"]', options);
}




// var hash = location.hash;

// if ($(hash).length > 0) {
//   $(window).scrollTop(0);
//   setTimeout(function () {
//     scroll.animateScroll(document.querySelector(target), options);
//   }, 700);
// }